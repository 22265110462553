<template>
  <validation-observer ref="simpleRules">
    <component>
      <b-overlay :show="show" rounded="sm">
        <b-card>
          <!-- Header: Personal Info -->
          <div class="d-flex">
            <feather-icon
              icon="EditIcon"
              size="19"
            />
            <h4 class="mb-0 ml-50">
              Details Pengajuan Diskon Khusus
            </h4>
          </div>
          <b-form class="mt-1" v-if="userDataInfoCount.length < 1">
            <b-row>
              <b-col md="12" class="text-center">
                <h5> Data Tidak Ditemukan! </h5>
              </b-col>
            </b-row>
          </b-form>
          <!-- Form: Personal Info Form -->
          <b-form class="mt-1" v-if="userDataInfoCount.length > 0">
            <b-row>
              <b-col md="2">
                <h6> Jenis Input</h6>
              </b-col>
              <b-col md="2">
                <h6> : {{ this.userDataInfo.trn_type.text }}</h6>
              </b-col>
              <b-col md="2">
                <h6> Nama Proyek</h6>
              </b-col>
              <b-col md="6">
                <h6> : {{ this.userDataInfo.trn_name_project }}</h6>
              </b-col>

              <b-col md="2">
                <h6> Dasar Hitung</h6>
              </b-col>
              <b-col md="2">
                <h6> : {{ this.userDataInfo.trn_dasarhitung.text }}</h6>
              </b-col>
              <b-col md="2">
                <h6> Mitra Usaha</h6>
              </b-col>
              <b-col md="6">
                <h6> : {{ this.userDataInfo.trn_mitrausaha.label }}</h6>
              </b-col>

              <b-col md="2">
                <h6> Tanggal Pengajuan</h6>
              </b-col>
              <b-col md="2">
                <h6> : {{ this.userDataInfo.trn_date_request }}</h6>
              </b-col>
              <b-col md="2">
                <h6> Periode Delivery</h6>
              </b-col>
              <b-col md="6">
                <h6> : {{ this.userDataInfo.trn_periode_start+" s/d "+this.userDataInfo.trn_periode_end }}</h6>
              </b-col>

              <b-col md="2">
                <h6> Program DF</h6>
              </b-col>
              <b-col md="2">
                <h6> : {{ userDataInfo.trn_program_df.text }}</h6>
              </b-col>
              <b-col md="2">
                <h6> Premi</h6>
              </b-col>
              <b-col md="2">
                <h6> : {{ this.userDataInfo.trn_premi.text }}</h6>
              </b-col>
              <b-col md="2">
                <h6> </h6>
              </b-col>
              <b-col md="2">
                <h6> </h6>
              </b-col>

              <b-col md="2">
                <h6> Catatan</h6>
              </b-col>
              <b-col md="6">
                <h6> : {{ userDataInfo.trn_notes }}</h6>
              </b-col>
              <b-col md="2">
                <h6> </h6>
              </b-col>
              <b-col md="2">
                <h6> </h6>
              </b-col>
                              
              <b-col md="2">
                <h6> Nilai Proyek</h6>
              </b-col>
              <b-col md="2">
                <h6> : Rp. {{ vueNumberFormat(this.tbl_sum_jual_req) }},-</h6>
              </b-col>
              <b-col md="2">
                <h6> Diskon diminta (%)</h6>
              </b-col>
              <b-col md="2">
                <h6> : {{ userDataInfo.trn_diskon_request }}%</h6>
              </b-col>                
              <b-col md="2">
                <h6> Diskon diminta (Rp)</h6>
              </b-col>
              <b-col md="2">
                <h6> : Rp. {{ vueNumberFormat(this.tbl_sum_harga_selisih) }},-</h6>
              </b-col>
              
              <b-col md="2">
                <h6> <strong>Total Harga Jual</strong></h6>
              </b-col>
              <b-col md="2">
                <h6> : <strong>Rp. {{ vueNumberFormat(this.tbl_sum_jual_req) }},-</strong></h6>
              </b-col>

              <br>
              <br>

              <b-table-simple
                hover
                caption-top
                responsive
                class="rounded-bottom mb-0"
              >
                <b-thead head-variant="light">
                  <b-tr>
                    <b-th>No</b-th>
                    <b-th>No.ACR</b-th>
                    <b-th>Sisa Kuota</b-th>
                    <b-th>Nama Product</b-th>
                    <b-th>Volume (Ton)</b-th>
                    <b-th>Price List (Rp)</b-th>
                    <b-th>Diskon Standart (%)</b-th>
                    <b-th>Harga Jual Standart</b-th>
                    <b-th>Harga Jual diminta</b-th>
                    <b-th>Selisih Harga</b-th>
                    <b-th>Ket Khusus</b-th>
                    <b-th>Tujuan Kirim</b-th>
                    <b-th>Franco</b-th>
                    <b-th>Ket Franco</b-th>
                  </b-tr>
                </b-thead>
                <b-tbody>
                  <b-tr v-for="(val,idx) in tbl" :key="idx">
                    <b-td>{{ val.trn_dtl_line }}</b-td>
                    <b-td>{{ val.trn_dtl_no_acr_input }}</b-td>
                    <b-td>{{ val.trn_dtl_sisa_kuota }}</b-td>
                    <b-td>{{ val.trn_dtl_group_kode }}</b-td>
                    <b-td class="text-left">{{ val.trn_dtl_volume}}</b-td>
                    <b-td class="text-right">{{ vueNumberFormat(val.trn_dtl_pricelist, {}) }}</b-td>
                    <b-td>{{ val.trn_dtl_diskonstandart}}%</b-td>
                    <b-td class="text-right">{{ vueNumberFormat(val.trn_dtl_sellprice, {}) }}</b-td>
                    <b-td class="text-right">{{ vueNumberFormat(val.trn_dtl_sellprice_req, {}) }}</b-td>
                    <b-td class="text-right">{{ vueNumberFormat((val.trn_dtl_sellprice_req - val.trn_dtl_sellprice), {}) }}</b-td>
                    <b-td>{{ val.trn_dtl_desc }}</b-td>
                    <b-td>{{ val.trn_dtl_shipto }}</b-td>
                    <b-td>{{ val.trn_dtl_franco == 'gudang' ? 'Gudang MU' : 'Lainnya' }}</b-td>
                    <b-td>{{ val.trn_dtl_franco_desc }}</b-td>
                  </b-tr>
                </b-tbody>
                <b-tfoot>
                <b-tr>
                  <b-td class="text-center" colspan="4"> &nbsp;&nbsp;&nbsp;&nbsp;
                      <strong>Total</strong>
                    </b-td>
                    <b-td class="text-left"><strong>{{ tbl_sum_vol }}</strong></b-td>
                    <b-td></b-td>
                    <b-td></b-td>
                    <b-td class="text-right"><strong>{{ vueNumberFormat(tbl_sum_jual_std, {}) }}</strong></b-td>
                    <b-td class="text-right"><strong>{{ vueNumberFormat(tbl_sum_jual_req, {}) }}</strong></b-td>
                    <b-td class="text-right"><strong>{{ vueNumberFormat(tbl_sum_harga_selisih, {}) }}</strong></b-td>
                    <b-td></b-td>
                    <b-td></b-td>
                    <b-td></b-td>
                    <b-td></b-td>
                  </b-tr>
                </b-tfoot>
              </b-table-simple>
            </b-row>
            <b-row class="mt-2">
              <b-col md="12">
                <h6> <strong>Catatan Approval : </strong></h6>
              </b-col>
              <b-col md="6">
                <b-form-textarea
                  id="textarea"
                  v-model="hist_desc"
                  placeholder="Input Catatan..."
                  rows="3"
                ></b-form-textarea>
              </b-col>
              <b-col md="12">&nbsp;</b-col>
              <b-col>
                <b-button
                  variant="outline-secondary"
                  class="mb-1 mb-sm-0 mr-0 mr-sm-1"
                  :block="$store.getters['app/currentBreakPoint'] === 'xs'"
                  :to="{ name: 'apps-approval-list'}"
                >
                  Back
                </b-button>
                <b-button
                  variant="primary"
                  class="mb-1 mb-sm-0 mr-0 mr-sm-1"
                  :block="$store.getters['app/currentBreakPoint'] === 'xs'"
                  @click="btn_reject()"
                >
                  Revisi
                </b-button>
                <b-button
                  variant="danger"
                  class="mb-1 mb-sm-0 mr-0 mr-sm-1"
                  :block="$store.getters['app/currentBreakPoint'] === 'xs'"
                  @click="status_detail(true, userDataInfo.trn_code)"
                >
                  History
                </b-button>
                <b-button
                  variant="success"
                  class="mb-1 mb-sm-0 mr-0 mr-sm-1"
                  :block="$store.getters['app/currentBreakPoint'] === 'xs'"
                  @click.prevent="btn_approve()"
                >
                  Approve
                </b-button>
              </b-col>
            </b-row>
          </b-form>
        </b-card>
      </b-overlay>

      <b-modal id="modal-xl" size="xl" title="Approval Atasan Sales"
        ref="status-modal"
      >
        <b-card>
          <b-row>
            <b-table-simple
              hover
              caption-top
              responsive
              class="rounded-bottom mb-0"
            >
              <b-thead head-variant="light">
                <b-tr class="text-center">
                  <b-th>No</b-th>
                  <b-th>Status</b-th>
                  <b-th>Approval</b-th>
                  <b-th>Level</b-th>
                  <b-th>Tanggal</b-th>
                  <b-th>Catatan</b-th>
                </b-tr>
              </b-thead>
              <b-tbody>
                <b-tr v-for="(val,idx) in tbl_detail_status" :key="idx">
                  <b-td class="text-center">{{ idx+1 }}.</b-td>
                  <b-td>{{ val.status }}</b-td>
                  <b-td>{{ val.approval }}</b-td>
                  <b-td class="text-center">{{ val.app_level }}</b-td>
                  <b-td class="text-center">{{ val.tanggal}}</b-td>
                  <b-td class="text-left">{{ val.catatan }}</b-td>
                </b-tr>
              </b-tbody>
            </b-table-simple>
          </b-row>
        </b-card>
        <template #modal-footer>
          <div class="w-100">
            <b-button
              variant="primary"
              size="sm"
              class="float-right"
              @click="status_detail(false, '')"
            >
              Close
            </b-button>
          </div>
        </template>
      </b-modal>

    </component>
  </validation-observer>
</template>

<script>
import useAppConfig from '@core/app-config/useAppConfig'
import {
  BCard, BRow, BCol, BForm, BFormGroup, BFormInput, BFormRadioGroup, BButton, BTableSimple, BThead, BTr, BTh, BTd, BTbody, BTfoot, BFormTextarea, BOverlay
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, email } from '@validations'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import axios from '@axios'
import { ref, onMounted } from '@vue/composition-api'
import router from '@/router'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {
  codeVueMultiBasic, codeVueMultiIcon, codeMultiPush, codeVueSelectionLimit, codeVueDir,
} from './code'
import { codeVariant } from './codetoast'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BFormInput,
    vSelect,
    BFormRadioGroup,
    BButton,
    ValidationProvider,
    ValidationObserver,
    BTableSimple,
    BThead,
    BTr,
    BTh,
    BTd,
    BTbody,
    BTfoot,
    BFormTextarea,
    BOverlay,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      show: false,
      trn_code: router.currentRoute.params.trn_code,
      emailValue: '',
      name: '',
      required,
      email,
      tbl: [],
      hist_desc:''
    }
  },
  created: function(){
    this.load_table(this.trn_code)
  },
  methods: {
    async load_table(value) {
      const result_table0 = await axios.post(`${useAppConfig().AppUrl}sales_pengajuan_sales/tbl_step2`, {trn_code: value})
      console.log(result_table0.data[0][1]);

      this.tbl = result_table0.data[0]
      let tbls = result_table0.data[0]
      let sum_vol = 0, sum_jual_std = 0, sum_jual_req = 0, sum_harga_selisih = 0
      for (let i = 0, len = tbls.length; i < len; i++) {
        // console.log(tbls[i].trn_dtl_volume);
        sum_vol += tbls[i].trn_dtl_volume
        sum_jual_std += tbls[i].trn_dtl_sellprice
        sum_jual_req += tbls[i].trn_dtl_sellprice_req
        // combo_mu.push({ label: mu[i].cust_code_name, value: mu[i].cust_code })
      }
      sum_harga_selisih = (sum_jual_req - sum_jual_std)

      this.tbl_sum_vol = sum_vol
      this.tbl_sum_jual_std = sum_jual_std
      this.tbl_sum_jual_req= sum_jual_req
      this.tbl_sum_harga_selisih= sum_harga_selisih
      // console.log(sum_vol);
    },
    async btn_reject(){
      if(this.hist_desc == ''){
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Notification',
            icon: 'BellIcon',
            text: 'Catatan approval harus diisi! ',
            variant: 'danger',
          },
        })
      }
      else{
        this.show = true
        // PROSES BACK TO SALES //
        const params = new URLSearchParams()
        // params.append('trn_code', this.userDataInfo.trn_code)
        // params.append('trn_flag', '2')
        // params.append('hist_desc', this.hist_desc)

        params.append('trn_code', this.userDataInfo.trn_code)
        params.append('trn_flag', '2')
        params.append('hist_type', 'Revisi')
        params.append('hist_approval', localStorage.getItem('emp_id'))
        params.append('hist_level', this.userDataInfo.trn_app_sales_user_lvl)
        params.append('hist_desc', this.hist_desc)
        const result = await axios.post(`${useAppConfig().AppUrl}approval_pengajuan_sales/reject_approval`, params)
        // console.log(result);

        if (result.data.message != 'Berhasil revisi data') {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Notification',
              icon: 'BellIcon',
              text: 'Data Gagal Direvisi. '+result.data.message,
              variant: 'danger',
            },
          })
        } 
        else{
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Notification',
              icon: 'EditIcon',
              text: 'Data Berhasil Direvisi',
              variant: 'success',
            },
          })
          this.$router.push('/apps/approval/list')
        }
        this.show = false
      }
    },
    async status_detail(sts, flag){
      // alert('status e opo? '+sts);
      if(sts == true){
        const result = await axios.post(`${useAppConfig().AppUrl}sales_pengajuan_sales/detail_status`, {trn_code: flag})
        this.tbl_detail_status = result.data[0]

        console.log(this.tbl_detail_status);  
        this.$refs['status-modal'].show()
      }
      else{
        this.tbl_detail_status = null
        this.$refs['status-modal'].hide()
      }
    },
    async btn_approve(){
      const params = new URLSearchParams()
      params.append('trn_code', this.userDataInfo.trn_code)
      params.append('hist_type', 'Normal')
      params.append('hist_approval', localStorage.getItem('emp_id'))
      params.append('hist_level', this.userDataInfo.trn_app_sales_user_lvl)
      params.append('hist_desc', this.hist_desc == '' ? 'Setujui' : this.hist_desc)

      // params.append('trn_code', resCekApproval[0].trn_code)
      // params.append('trn_flag', '1')
      // params.append('hist_type', 'Normal')
      // params.append('hist_desc', 'Setujui')
      this.show = true
      const cekApproval = await axios.post(`${useAppConfig().AppUrl}approval_pengajuan_sales/cek_approval`, params)
      let resCekApproval = cekApproval.data[0].data
      console.log(resCekApproval.length);
      if(resCekApproval.length > 0){
        console.log(resCekApproval);
        if(resCekApproval[0].trn_diskon_request >= resCekApproval[0].app_batas_bawah && resCekApproval[0].trn_diskon_request <= resCekApproval[0].app_batas_atas){
          params.append('trn_flag', '1')
          params.append('hist_approval_next', resCekApproval[0].app_map_user_pk)
          params.append('hist_level_next', resCekApproval[0].app_level)
          const result = await axios.post(`${useAppConfig().AppUrl}approval_pengajuan_sales/send_approval`, params)
          if (result.data.message != 'Berhasil setujui data') {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Notification',
                icon: 'BellIcon',
                text: 'Data Gagal Disetujui. '+result.data.message,
                variant: 'danger',
              },
            })
            this.show = false
          } 
          else{
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Notification',
                icon: 'EditIcon',
                text: 'Data Berhasil Disetujui',
                variant: 'success',
              },
            })
            this.show = false
            this.$router.push('/apps/approval/list')
          }
        }
        else{
          params.append('trn_flag', '3')
          params.append('trn_type', this.userDataInfo.trn_type.value)
          params.append('trn_dasarhitung', this.userDataInfo.trn_dasarhitung.value)
          params.append('trn_mitrausaha', this.userDataInfo.trn_mitrausaha.value)
          params.append('trn_diskon_request', this.userDataInfo.trn_diskon_request)
          params.append('tbl_sum_jual_req', this.tbl_sum_jual_req)
          params.append('trn_date_request', this.userDataInfo.trn_date_request)
          params.append('trn_periode_start', this.userDataInfo.trn_periode_start)
          params.append('trn_periode_end', this.userDataInfo.trn_periode_end)
          params.append('trn_name_project', this.userDataInfo.trn_name_project)
          // params.append('product', this.userDataInfo.trn_periode_end)
          params.append('trn_domain', this.userDataInfo.trn_domain)
          const result = await axios.post(`${useAppConfig().AppUrl}approval_pengajuan_sales/send_approval_generate`, params)
          if (result.data.message != 'Berhasil setujui data') {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Notification',
                icon: 'BellIcon',
                text: 'Data Gagal Disetujui. '+result.data.message,
                variant: 'danger',
              },
            })
            this.show = false
          } 
          else{
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Notification',
                icon: 'EditIcon',
                text: 'Data Berhasil Disetujui. '+result.data.status_generate+' '+result.data.message_generate,
                variant: 'success',
              },
            })
            this.show = false
            this.$router.push('/apps/approval/list')
          }
          // this.$toast({
          //   component: ToastificationContent,
          //   props: {
          //     title: 'Notification',
          //     icon: 'BellIcon',
          //     text: 'Data Generate No. ACR Range Diskon',
          //     variant: 'success',
          //   },
          // })
        }
      }
      else{
        params.append('trn_flag', '3')
        params.append('trn_type', this.userDataInfo.trn_type.value)
        params.append('trn_dasarhitung', this.userDataInfo.trn_dasarhitung.value)
        params.append('trn_mitrausaha', this.userDataInfo.trn_mitrausaha.value)
        params.append('trn_diskon_request', this.userDataInfo.trn_diskon_request)
        params.append('tbl_sum_jual_req', this.tbl_sum_jual_req)
        params.append('trn_date_request', this.userDataInfo.trn_date_request)
        params.append('trn_periode_start', this.userDataInfo.trn_periode_start)
        params.append('trn_periode_end', this.userDataInfo.trn_periode_end)
        params.append('trn_name_project', this.userDataInfo.trn_name_project)
        // params.append('product', this.userDataInfo.trn_periode_end)
        params.append('trn_domain', this.userDataInfo.trn_domain)
        const result = await axios.post(`${useAppConfig().AppUrl}approval_pengajuan_sales/send_approval_generate`, params)
        if (result.data.message != 'Berhasil setujui data') {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Notification',
              icon: 'BellIcon',
              text: 'Data Gagal Disetujui. '+result.data.message,
              variant: 'danger',
            },
          })
          this.show = false
        } 
        else{
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Notification',
              icon: 'EditIcon',
              text: 'Data Berhasil Disetujui. '+result.data.status_generate+' '+result.data.message_generate,
              variant: 'success',
            },
          })
          this.show = false
          this.$router.push('/apps/approval/list')
        }
        // this.$toast({
        //   component: ToastificationContent,
        //   props: {
        //     title: 'Notification',
        //     icon: 'BellIcon',
        //     text: 'Data Generate No. ACR Last Approval',
        //     variant: 'success',
        //   },
        // })
      }
    }
  },
  setup() {
    const userDataInfoCount = ref({})
    const userDataInfo = ref({})
    const tbl_detail_status = ref([])

    onMounted(async () => {
      // alert(router.currentRoute.params.trn_code)
      const paramsEdit = new URLSearchParams()
            paramsEdit.append('trn_code', router.currentRoute.params.trn_code)
            paramsEdit.append('appv_id', localStorage.getItem('emp_id'))
      const result = await axios.get(`${useAppConfig().AppUrl}approval_pengajuan_sales/add_edit?${paramsEdit}`)
      console.log('result : '+ result.data.length);
      if(result.data.length > 0){
        userDataInfoCount.value = result.data
        userDataInfo.value = result.data[0]
      }
      else{
        userDataInfoCount.value = result.data
        userDataInfo.value = result.data
      }
      
    })

    const aktifOptions = [
      { text: 'Ya', value: '0' },
      { text: 'Tidak', value: '1' },
    ]

    const urutanOptions = [
    ]

    const divisiOptions = [

    ]

    const namaOptions = [

    ]

    const levelOptions = [
      '1',
      '2',
      '3',
      '4',
      '5',
    ]

    return {
      codeVueMultiBasic,
      codeVueMultiIcon,
      codeMultiPush,
      codeVueSelectionLimit,
      codeVueDir,
      aktifOptions,
      userDataInfo,
      userDataInfoCount,
      urutanOptions,
      divisiOptions,
      namaOptions,
      levelOptions,
      codeVariant,
      tbl_detail_status
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>

<style>

</style>
